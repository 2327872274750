body {
  margin: 0;
  padding: 0;
}

.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 50px;
  border: 1px dashed rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  transition: 0.2s;
  &.is-active {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.fake-btn {
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.file-msg {
  font-size: small;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  &:focus {
    outline: none;
  }
}

.itemtype {
  width: 100%;
  height: 100%;
  padding-top: 1rem !important;
  i {
    font-size: 3rem;
  }
}
.itemtypeContainer {
  height: 100%;
}

.btn-outline-primary {
  color: #8a4ada !important;
  border-color: #8a4ada !important;
  &:hover {
    color: white !important;
    background-color: #8a4ada !important;
  }
}

.frm_button {
  height: 50px;
  border-style: none;
  background: #8a4ada;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #fff !important;
  margin: 0.5rem 0;
  padding: 0.5rem 2rem;
  width: 100%;
  border-radius: 8px;
  text-decoration: none;
}
.d-disabled {
  color: grey !important;
  border-color: grey !important;
}

.coauthorsAddButton {
  margin: auto 5px !important;
  color: grey;
  cursor: pointer;

  &:hover {
    color: #8a4ada;
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
}
