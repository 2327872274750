.nft-container
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    font-family: Releway-regular
    @media screen and ( max-width: 1000px )
        padding: 30px 15px
        flex-direction: column
        flex-wrap: nowrap
        
    .nft-left-column
        width: 45%
        
        overflow: hidden
        @media screen and ( max-width: 1000px )
            width: 100%
            
        .nft-img
            overflow: hidden
            border-radius: 16px
            img
                width: 100%
            video
                
                width: 100%
                object-fit: cover
                overflow: hidden
    .nft-data
        width: 49%
        
        
        overflow: hidden
        .nft-collections
            color: #894ADA
            font-size: 14px
            margin: 30px 10px 0 0
            text-overflow: ellipsis
            h1
                text-overflow: ellipsis
        @media screen and ( max-width: 1000px )
            width: 100%
    .ntf-table-1
        
        @media screen and ( max-width: 350px )
            display: none
    .ntf-table
        width: 100%
    .token-id
        color: #fff
        margin-right: 15px
        font-size:24px
    .nft-category
        color: #8458EF
        font-size:24px
        margin-right: 10px
    .ntf-mobile-description 
        display: none
        @media screen and ( max-width: 1000px )
            display: block
            margin-top: 30px
    .ntf-desctop-description 
        display: block
        @media screen and ( max-width: 1000px )
            display: none
    .ntf-desctop-description-k
        margin-top: 7px
        margin-bottom: 7px
        display: flex
        &:first-child
            margin-top: 30px
        h2
            margin-right: 20px

      
       

            

.nft-same
    width: 100%
    position: relative
    z-index: 2    
    .nft-grid
        width: 100%
        margin-top: 20px
        display: grid
        grid-template-columns: repeat(3, 1fr)
        column-gap: 20px
        row-gap: 20px
        @media screen and ( max-width: 1300px )
            grid-template-columns: repeat(2, 1fr)
            row-gap: 20px
        @media screen and ( max-width: 1000px )
            grid-template-columns:  1fr
            row-gap: 20px

            