/* * {
  color: #fff;
} */

.border-grarient {
  position: relative;
}
.absolute {
  position: absolute;
}

.relative {
  position: relative;
  z-index: 2;
}

.border-grarient:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 15px;
  padding: 2px;
  background: -o-linear-gradient(199.65deg, #dd24e1 1.5%, #5117f4 102.15%);
  background: linear-gradient(199.65deg, #dd24e1 1.5%, #5117f4 102.15%);

  -webkit-mask: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0, #fff)
      )
      content-box,
    -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}
.custom-bg-gradient {
  border-radius: 15px;
  background: linear-gradient(95.33deg, #004af9 0%, #db00ff 100.88%) !important;
}
