@font-face {
  font-family: Releway-bold;
  src: url("./shared/fonts/Raleway-Bold.woff") format("woff");
}
@font-face {
  font-family: Releway-regular;
  src: url("./shared/fonts/Raleway-Regular.woff") format("woff");
}
@font-face {
  font-family: Releway-medium;
  src: url("./shared/fonts/Raleway-Medium.woff") format("woff");
}
@font-face {
  font-family: Releway-semiBold;
  src: url("./shared/fonts/Raleway-SemiBold.woff") format("woff");
}
@font-face {
  font-family: Montserrat-semiBold;
  src: url("./shared/fonts/Montserrat-SemiBold.woff") format("woff");
}
@font-face {
  font-family: Montserrat-regular;
  src: url("./shared/fonts/Montserrat-Regular.woff") format("woff");
}
body {
  margin: 0;
  height: 100vh;
  overflow: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 p {
  margin: 0 !important;
  margin-bottom: 0 !important;
  margin-block-start: 0;
  margin-block-end: 0;
}

::-webkit-scrollbar {
  width: 15px;
  margin: 15px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #aaaaaa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  /* height: 100%; */
}
