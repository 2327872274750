.lk-container
    display: flex
  
.lk-container.main-container
    margin-top: 30px
        
.lk-logo
    width: 180px
    height: 180px
    border-radius: 100%
    margin-bottom: 8px 
    overflow: hidden
    object-fit: cover
.lk-name
    margin-bottom: 8px 
    font-family: Releway-semiBold
    
.lk-id
    color: #894ADA
    margin-bottom: 8px 

.profileBlock
    width: 180px !important
    flex-basis: 180px
    flex-shrink: 0
    margin-right: 94px
    font-family: Releway-semiBold
    font-size: 16px
    text-align: center
    transform: translateY(-130px)
    color: #fff



    .lk-refTitle
        font-family: Releway-regular
        font-size: 12px
        text-align: left
        margin-bottom: 5px
    .lk-referal
        box-sizing: border-box
        margin-bottom: 8px
        height: 35px
        width: 100%
        // border: 1px solid #F2E9EA
        border-radius: 8px
        display: flex
        align-items: center
        justify-content: space-between
        padding: 0px 14px
        font-family: Releway-regular
        font-size: 14px
        &::before
            z-index: -1
    .lk-userType
        font-family: Releway-regular
        font-size: 16px
        color: #777777
        margin: 70px 0 10px 0
.mainBlock
    flex-grow: 3
    .lk-title
        font-family: Releway-bold
        font-size: 32px
        padding-bottom: 12px
        margin-bottom: 16px
        border-bottom: 2px solid #F2E9EA
    .lk-menu
        display: flex
        button
            background: none
            border: none
            font-family: Releway-regular
            font-size: 20px
            padding: 0 0 12px 0
            margin-right: 34px
            margin-bottom: 19px
            border-bottom: 2px solid #894ADA

.mob-lk-chevron
    margin-left: 10px
    width: 13px